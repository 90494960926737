.ImagesTable {
    position: relative;
}

.ImagesTable .submitCancelButtons {
    position: absolute;
    right: 0px;
    bottom: -30px;
}

.ImagesTable .addFrame {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    display: flex;
    transition: opacity 0.2s ease;
    order: 999999;
}

.ImagesTable .addFrame button {
    display: flex;
    position: relative;
    width: 100px;
    height: 100px;
    border: none;
    outline: none;
    background: center no-repeat;
    transition: transform 0.2s ease;
    -webkit-transition: transform 0.2s ease;
    -moz-transition: transform 0.2s ease;
    -ms-transition: transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    filter: invert(50%);
}

.ImagesTable .addFrame button:hover {
    transform: scale(1.1) rotate(10deg);
    -webkit-transform: scale(1.1) rotate(10deg);
    -moz-transform: scale(1.1) rotate(10deg);
    -ms-transform: scale(1.1) rotate(10deg);
    -o-transform: scale(1.1) rotate(10deg);
    transition: transform 0.4s ease;
    -webkit-transition: transform 0.4s ease;
    -moz-transition: transform 0.4s ease;
    -ms-transition: transform 0.4s ease;
    -o-transition: transform 0.4s ease;
    cursor: pointer;
}
