.uploader {
    position: fixed;
    top: 10vh;
    left: 10vw;
    width: 80vw;
    height: 80vh;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.uploader.hidden {
    display: none;
}

.uploader.displayed {
    display: flex;
}

.uploaderImagesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    height: 80%;
    width: 90%;
    margin: 5% auto 0 auto;

    overflow-y: scroll;
    overflow-x: hidden;
}

/* Le boutton ajouter et les images */
.uploaderImagesContainer > * {
    position: relative;
    height: 150px;
    margin: 0 10px 10px 0;

    cursor: pointer;
}

.uploaderImage img {
    height: 100%;
    filter: sepia(0%) brightness(1);
    transition: filter 0.2s ease;
}

/* UPLOADER IMAGES BUTTONS *******************************************/

.uploaderImage:hover img {
    filter: sepia(70%) brightness(0.7);
    transition: filter 0.5s ease;
}

.uploaderImageButtonsContainer {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    -ms-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
}

.uploaderImage:hover .uploaderImageButtonsContainer {
    opacity: 1;
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -ms-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
}

.uploaderImageButton {
    display: flex;
    position: relative;
    width: 20%;
    height: 100%;
    border: none;
    outline: none;
    background: center no-repeat;
    transition: transform 0.2s ease;
    -webkit-transition: transform 0.2s ease;
    -moz-transition: transform 0.2s ease;
    -ms-transition: transform 0.2s ease;
    -o-transition: transform 0.2s ease;
}

.uploaderImageButton:hover {
    transform: scale(1.1) rotate(10deg);
    -webkit-transform: scale(1.1) rotate(10deg);
    -moz-transform: scale(1.1) rotate(10deg);
    -ms-transform: scale(1.1) rotate(10deg);
    -o-transform: scale(1.1) rotate(10deg);
    transition: transform 0.4s ease;
    -webkit-transition: transform 0.4s ease;
    -moz-transition: transform 0.4s ease;
    -ms-transition: transform 0.4s ease;
    -o-transition: transform 0.4s ease;
    cursor: pointer;
}

.uploaderImageButton.picture {
    background-image: url(../../assets/icons/picture.svg);
}

.uploaderImageButton.remove {
    background-image: url(../../assets/icons/bin.svg);
}

/* UPLOADER INPUT *****************/

.uploaderInput {
    padding: 0 !important;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaderInput label {
    display: flex;
    cursor: pointer;
}

.uploaderInput label img {
    height: 80px;
    width: 80px;
    filter: invert(50%);
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}

/* UPLOADER BUTTONS ****************/

.uploaderButtonsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    height: 10%;
    width: 90%;
    margin: 0 auto;

    overflow: scroll;
}

.backButton {
    margin: 0 !important;
    padding: 15px 15px;
    border-radius: calc(15px + 1em);
    background-color: #f29f57;

    font-size: 1em;
    font-family: laca;
    font-weight: 200;
    color: white;
}

.backButton .circle {
    background-color: #ecbd64;
    width: 10em;
    height: 10em;
}

/* UPLOADER BACKGROUND */
.uploaderBackground {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.1);
}

.uploaderBackground.displayed {
    display: flex;
}

.uploaderBackground.hidden {
    display: none;
}
