.st0 {
    fill: #7ecefc;
}
.st1 {
    fill: #f8fbff;
}
.st2 {
    fill: #603813;
}
.st3 {
    fill: #046b56;
}
.st4 {
    fill: #429a80;
}
.st5 {
    fill: #aab170;
}
.st6 {
    fill: #665e31;
}
.st7 {
    fill: #5c8400;
}
.st8 {
    fill: #88ad25;
}
.st9 {
    fill: #c6b32e;
}
.st10 {
    fill: #395d00;
}
.st11 {
    fill: #87b700;
}
.st12 {
    fill: #bce03b;
}
.st13 {
    fill: #8b5e41;
}
.st14 {
    fill: #efda48;
}
.st15 {
    fill: #8f8304;
}
.st16 {
    fill: #a59016;
}
.st17 {
    fill: #c8b600;
}
.st18 {
    fill: #71891b;
}
.st19 {
    fill: #009245;
}
.st20 {
    fill: #597c15;
}
.st22 {
    fill: #acd4ea;
}
.st23 {
    fill: #8871ea;
}
.st24 {
    fill: #ebebeb;
}
.st25 {
    fill: #f4efc6;
}
.st26 {
    fill: #714e28;
}
.st29 {
    fill: #895c27;
}
.st30 {
    fill: none;
    stroke: #895c27;
    stroke-width: 3;
    stroke-miterlimit: 10;
}
.st32 {
    fill: #ccc;
}
.st33 {
    fill: #fff6e5;
}
.st35 {
    fill: #f29876;
}
.st56 {
    fill: #db846a;
}
.st66 {
    fill: #a84240;
}
.st67 {
    fill: #c69c6d;
}
.st69 {
    fill: #754c24;
}
.st70 {
    fill: #b3b3b3;
}
.st71 {
    fill: #c7b299;
}
.st72 {
    fill: #bdecff;
}
.st74 {
    fill: #a86f40;
}
.st75 {
    fill: #a1a524;
}
.st76 {
    fill: #776b12;
}
.st77 {
    fill: #566813;
}
.st79 {
    display: inline;
    fill: #8c6239;
}
.st80 {
    fill: #83838e;
}
.st81 {
    fill: #9c9ca0;
}
.st82 {
    fill: #808084;
}
.st83 {
    fill: #939399;
}
.st84 {
    fill: #818189;
}
.st85 {
    fill: #aaaab2;
}
.st86 {
    fill: #79797c;
}
.st87 {
    fill: #e29256;
}
.st88 {
    fill: #93939e;
}
.st89 {
    fill: #6d6d72;
}
.st91 {
    fill: #797984;
}
.st94 {
    fill: #6a6a6d;
}
.st95 {
    fill: #5a5a5e;
}
.st96 {
    fill: #727275;
}
.st97 {
    fill: #5d5d72;
}
.st98 {
    fill: #666;
}
.st99 {
    fill: #71717c;
}
.st100 {
    fill: #86868e;
}
.st104 {
    fill: #5c5c66;
}
.st107 {
    fill: #79797a;
}
.st110 {
    fill: #45454c;
}
.st111 {
    fill: #6b6b7c;
}
.st112 {
    fill: #6f6f75;
}
.st113 {
    fill: #4b4b4f;
}
.st114 {
    fill: #72727a;
}
.st115 {
    fill: #666668;
}
.st117 {
    fill: #717177;
}
.st118 {
    fill: #4d4d51;
}
.st123 {
    fill: #5b5b6b;
}
.st124 {
    fill: #64646b;
}
.st125,
.st126 {
    fill: #acd4ea;
    stroke: #665e31;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
.st126 {
    stroke-width: 2;
}
.st135 {
    fill: #818184;
}
.st136 {
    fill: #656570;
}
.st137 {
    fill: #8d8d93;
}
.st138 {
    fill: #6b6b72;
}
.st139 {
    fill: #8c8c96;
}
.st141 {
    fill: #f29f57;
}
.st144 {
    fill: #929299;
}
.st152 {
    fill: #6a6a72;
}
.st158 {
    fill: #8d8d96;
}
.st159 {
    fill: #74747c;
}
.st160 {
    fill: #838389;
}
.st161 {
    fill: #7e7e87;
}
.st162 {
    fill: #858589;
}
.st163 {
    fill: #737375;
}
.st164 {
    fill: #828287;
}
.st168 {
    fill: #7a7a7f;
}
.st171 {
    fill: #4f4f54;
}
.st173 {
    fill: #8f8f9b;
}
.st177 {
    fill: #696970;
}
.st188 {
    fill: #f2c483;
}
.st189 {
    fill: #f27355;
}
.st190 {
    fill: #f77d61;
}
.st191 {
    fill: #f46442;
}
.st192 {
    fill: #e06244;
}
.st193 {
    fill: #ff7f66;
}
.st194 {
    fill: #e86c54;
}
