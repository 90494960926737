@font-face {
    font-family: Recoleta;
    font-weight: 100;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-Thin.ttf);
}

@font-face {
    font-family: Recoleta;
    font-weight: 200;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-Light.ttf);
}

@font-face {
    font-family: Recoleta;
    font-weight: 300;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-Regular.ttf);
}

@font-face {
    font-family: Recoleta;
    font-weight: 400;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-SemiBold.ttf);
}

@font-face {
    font-family: Recoleta;
    font-weight: 500;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-Bold.ttf);
}

@font-face {
    font-family: Recoleta;
    font-weight: 600;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-Black.ttf);
}

@font-face {
    font-family: Recoleta;
    font-weight: 700;
    font-style: normal;
    src: url(./assets/fonts/recoleta/Recoleta-Medium.ttf);
}
