/* ANIMATIONS */

/***************************************************************************************/
/*                                   ROUE DU MOULIN                                    */
/***************************************************************************************/

.notIE g[id^="roue"] {
    animation: rotate 6s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
    position: relative;
}

@keyframes rotate {
    from {
        transform: rotate(0deg) translate(0%, 0%);
    }
    to {
        transform: rotate(360deg) translate(0%, 0%);
    }
}

@-webkit-keyframes rotate {
    from {
        transform: rotate(0deg) translate(0%, 0%);
    }
    to {
        transform: rotate(360deg) translate(0%, 0%);
    }
}

/***************************************************************************************/
/*                                       ARBRES                                        */
/***************************************************************************************/

.notIE g[id^="arbre"] {
    animation-name: wind;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    transform-origin: 50% 100%;
    transform-box: fill-box;
    position: relative;
}

.notIE g[id^="arbre"][id$="0_"] {
    animation-duration: 3s;
    animation-name: wind;
}

.notIE g[id^="arbre"][id$="1_"] {
    animation-duration: 3.2s;
    animation-name: wind2;
}

.notIE g[id^="arbre"][id$="2_"] {
    animation-duration: 3.4s;
    animation-name: wind;
}

.notIE g[id^="arbre"][id$="3_"] {
    animation-duration: 3.6s;
    animation-name: wind2;
}

.notIE g[id^="arbre"][id$="4_"] {
    animation-duration: 3.8s;
    animation-name: wind;
}

.notIE g[id^="arbre"][id$="5_"] {
    animation-duration: 4s;
    animation-name: wind2;
}

.notIE g[id^="arbre"][id$="6_"] {
    animation-duration: 4.2s;
    animation-name: wind;
}

.notIE g[id^="arbre"][id$="7_"] {
    animation-duration: 4.4s;
    animation-name: wind2;
}

.notIE g[id^="arbre"][id$="8_"] {
    animation-duration: 4.6s;
    animation-name: wind;
}

.notIE g[id^="arbre"][id$="9_"] {
    animation-duration: 4.8s;
    animation-name: wind2;
}

@keyframes wind {
    from {
        transform: rotate(-1deg) translate(0%, 0%);
    }
    to {
        transform: rotate(2deg) translate(0%, 0%);
    }
}

@-webkit-keyframes wind {
    from {
        transform: rotate(-1deg) translate(0%, 0%);
    }
    to {
        transform: rotate(2deg) translate(0%, 0%);
    }
}

@keyframes wind2 {
    from {
        transform: rotate(-2deg) translate(0%, 0%);
    }
    to {
        transform: rotate(3deg) translate(0%, 0%);
    }
}

@-webkit-keyframes wind2 {
    from {
        transform: rotate(-2deg) translate(0%, 0%);
    }
    to {
        transform: rotate(3deg) translate(0%, 0%);
    }
}

/***************************************************************************************/
/*                                    FEUILLES D'ARBRES                                */
/***************************************************************************************/

.notIE g[id^="feuilles"] {
    animation-name: leafWind;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    transform-origin: 50% 100%;
    transform-box: fill-box;
    position: relative;
}

.notIE g[id^="feuilles"][id$="0_"] {
    animation-duration: 3s;
}

.notIE g[id^="feuilles"][id$="1_"] {
    animation-duration: 3.2s;
}

.notIE g[id^="feuilles"][id$="2_"] {
    animation-duration: 3.4s;
}

.notIE g[id^="feuilles"][id$="3_"] {
    animation-duration: 3.6s;
}

.notIE g[id^="feuilles"][id$="4_"] {
    animation-duration: 3.8s;
}

.notIE g[id^="feuilles"][id$="5_"] {
    animation-duration: 4s;
}

.notIE g[id^="feuilles"][id$="6_"] {
    animation-duration: 4.2s;
}

.notIE g[id^="feuilles"][id$="7_"] {
    animation-duration: 4.4s;
}

.notIE g[id^="feuilles"][id$="8_"] {
    animation-duration: 4.6s;
}

.notIE g[id^="feuilles"][id$="9_"] {
    animation-duration: 4.8s;
}

@keyframes leafWind {
    from {
        transform: rotate(-2deg) translate(0%, 0%) skew(0deg, 0deg);
    }
    to {
        transform: rotate(4deg) translate(0%, 0%) skew(-3deg, 3deg);
    }
}

@-webkit-keyframes leafWind {
    from {
        transform: rotate(-2deg) translate(0%, 0%) skew(0deg, 0deg);
    }
    to {
        transform: rotate(4deg) translate(0%, 0%) skew(-3deg, 3deg);
    }
}

/***************************************************************************************/
/*                                    FEUILLES DE VIGNE                                */
/***************************************************************************************/

.notIE path[id^="vigne"] {
    animation-name: vine;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;

    transform-box: fill-box;
    position: relative;
}

.notIE g[id^="MOULIN"] path[id^="vigne"] {
    transform-origin: 10% 50%;
}

.notIE g[id^="maison_droite"] path[id^="vigne"] {
    transform-origin: 90% 50%;
}

.notIE path[id^="vigne"][id$="0_"] {
    animation-duration: 1s;
}

.notIE path[id^="vigne"][id$="1_"] {
    animation-duration: 1.6s;
}

.notIE path[id^="vigne"][id$="2_"] {
    animation-duration: 1.7s;
}

.notIE path[id^="vigne"][id$="3_"] {
    animation-duration: 1.8s;
}

.notIE g[id^="feuilles"][id$="4_"] {
    animation-duration: 1.9s;
}

.notIE path[id^="vigne"][id$="5_"] {
    animation-duration: 2s;
}

.notIE path[id^="vigne"][id$="6_"] {
    animation-duration: 2.1s;
}

.notIE path[id^="vigne"][id$="7_"] {
    animation-duration: 2.2s;
}

.notIE path[id^="vigne"][id$="8_"] {
    animation-duration: 2.3s;
}

.notIE path[id^="vigne"][id$="9_"] {
    animation-duration: 2.4s;
}

/* MOULIN*/

@keyframes vine {
    from {
        transform: rotate(-15deg) scale(1.1, 0.9);
    }
    to {
        transform: rotate(15deg) scale(0.9, 1.1);
    }
}

@-webkit-keyframes vine {
    from {
        transform: rotate(-15deg) scale(1.1, 0.9);
    }
    to {
        transform: rotate(15deg) scale(0.9, 1.1);
    }
}

/***************************************************************************************/
/*                                    FUMEE DES CHEMINEES                              */
/***************************************************************************************/

.notIE circle[id^="fumee"] {
    animation-name: smoke;
    animation-duration: 14s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transform-origin: 50px 50%;

    transform-box: fill-box;

    position: relative;
    opacity: 0;
}

.notIE circle[id^="fumee1"] {
    animation-delay: 0s;
}

.notIE circle[id^="fumee2"] {
    animation-delay: 1s;
}

.notIE circle[id^="fumee3"] {
    animation-delay: 2s;
}

.notIE circle[id^="fumee4"] {
    animation-delay: 3s;
}

.notIE circle[id^="fumee5"] {
    animation-delay: 4s;
}

.notIE circle[id^="fumee6"] {
    animation-delay: 5s;
}

.notIE circle[id^="fumee7"] {
    animation-delay: 6s;
}

.notIE circle[id^="fumee8"] {
    animation-delay: 7s;
}

.notIE circle[id^="fumee9"] {
    animation-delay: 8s;
}

.notIE circle[id^="fumee10"] {
    animation-delay: 9s;
}

.notIE circle[id^="fumee11"] {
    animation-delay: 10s;
}

.notIE circle[id^="fumee12"] {
    animation-delay: 11s;
}

.notIE circle[id^="fumee13"] {
    animation-delay: 12s;
}

.notIE circle[id^="fumee14"] {
    animation-delay: 13s;
}

@keyframes smoke {
    0% {
        transform: translate(-35px, 5px) scale(0) rotate(0turn);
        opacity: 0;
    }
    5% {
        transform: translate(-20px, -5px) scale(0.5) rotate(0turn);
        opacity: 1;
    }
    100% {
        transform: translate(150px, -50px) scale(1.5) rotate(0.3turn);
        opacity: 0;
    }
}

@keyframes smoke {
    0% {
        transform: translate(-35px, 5px) scale(0) rotate(0turn);
        opacity: 0;
    }
    5% {
        transform: translate(-20px, -5px) scale(0.5) rotate(0turn);
        opacity: 1;
    }
    100% {
        transform: translate(150px, -50px) scale(1.5) rotate(0.3turn);
        opacity: 0;
    }
}

/***************************************************************************************/
/*                                       NUAGES                                        */
/***************************************************************************************/

.notIE path[id^="n"] {
    animation-name: cloud;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    transform-origin: center;
    transform-box: fill-box;
    position: relative;
}

.notIE path[id^="n"][id$="0_"] {
    animation-duration: 3s;
}

.notIE path[id^="n"][id$="1_"] {
    animation-duration: 3.2s;
}

.notIE path[id^="n"][id$="2_"] {
    animation-duration: 3.4s;
}

.notIE path[id^="n"][id$="3_"] {
    animation-duration: 3.6s;
}

.notIE path[id^="n"][id$="4_"] {
    animation-duration: 3.8s;
}

.notIE path[id^="n"][id$="5_"] {
    animation-duration: 4s;
}

.notIE path[id^="n"][id$="6_"] {
    animation-duration: 4.2s;
}

.notIE path[id^="n"][id$="7_"] {
    animation-duration: 4.4s;
}

.notIE path[id^="n"][id$="8_"] {
    animation-duration: 4.6s;
}

.notIE path[id^="n"][id$="9_"] {
    animation-duration: 4.8s;
}

@keyframes cloud {
    from {
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(10px, 3px);
    }
}

@-webkit-keyframes cloud {
    from {
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(10px, 3px);
    }
}

/***************************************************************************************/
/*                                       FLEURS                                        */
/***************************************************************************************/

.notIE g[id^="fleur"] {
    animation-name: flower;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    transform-origin: 50% 100%;
    transform-box: fill-box;
    position: relative;
}

.notIE g[id^="fleur"][id$="0_"] {
    animation-duration: 1.5s;
}

.notIE g[id^="fleur"][id$="1_"] {
    animation-duration: 1.6s;
}

.notIE g[id^="fleur"][id$="2_"] {
    animation-duration: 1.7s;
}

.notIE g[id^="fleur"][id$="3_"] {
    animation-duration: 1.8s;
}

.notIE g[id^="fleur "][id$="4_"] {
    animation-duration: 1.9s;
}

.notIE g[id^="fleur"][id$="5_"] {
    animation-duration: 2s;
}

.notIE g[id^="fleur"][id$="6_"] {
    animation-duration: 2.1s;
}

.notIE g[id^="fleur"][id$="7_"] {
    animation-duration: 2.2s;
}

.notIE g[id^="fleur"][id$="8_"] {
    animation-duration: 2.3s;
}

.notIE g[id^="fleur"][id$="9_"] {
    animation-duration: 2.4s;
}

@keyframes flower {
    from {
        transform: rotate(-10deg) translate(0%, 0%);
    }
    to {
        transform: rotate(20deg) translate(0%, 0%);
    }
}

@-webkit-keyframes flower {
    from {
        transform: rotate(-10deg) translate(0%, 0%);
    }
    to {
        transform: rotate(20deg) translate(0%, 0%);
    }
}

/***************************************************************************************/
/*                                        BUISSONS                                     */
/***************************************************************************************/

.notIE g[id^="buisson"] {
    animation-name: buisson;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    transform-origin: 50% 100%;
    transform-box: fill-box;
    position: relative;
}

.notIE g[id^="buisson"][id$="0_"] {
    animation-duration: 3s;
}

.notIE g[id^="buisson"][id$="1_"] {
    animation-duration: 3.2s;
}

.notIE g[id^="buisson"][id$="2_"] {
    animation-duration: 3.4s;
}

.notIE g[id^="buisson"][id$="3_"] {
    animation-duration: 3.6s;
}

.notIE g[id^="buisson"][id$="4_"] {
    animation-duration: 3.8s;
}

.notIE g[id^="buisson"][id$="5_"] {
    animation-duration: 4s;
}

.notIE g[id^="buisson"][id$="6_"] {
    animation-duration: 4.2s;
}

.notIE g[id^="buisson"][id$="7_"] {
    animation-duration: 4.4s;
}

.notIE g[id^="buisson"][id$="8_"] {
    animation-duration: 4.6s;
}

.notIE g[id^="buisson"][id$="9_"] {
    animation-duration: 4.8s;
}

@keyframes buisson {
    from {
        transform: rotate(-1deg) translate(0, 2px) skew(0deg, 0deg);
    }
    to {
        transform: rotate(1deg) translate(0, 2px) skew(-5deg, 5deg);
    }
}

@-webkit-keyframes buisson {
    from {
        transform: rotate(-1deg) translate(0, 2px) skew(0deg, 0deg);
    }
    to {
        transform: rotate(1deg) translate(0, 2px) skew(-5deg, 5deg);
    }
}

/***************************************************************************************/
/*                                GOUTTES D'EAU DE LA ROUE                             */
/***************************************************************************************/

.notIE [id^="goutte"] {
    animation-name: goutte;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 0 50px;

    transform-box: fill-box;
    position: relative;
}

.notIE [id^="goutte"][id$="0_"] {
    animation-delay: 0s;
}

.notIE [id^="goutte"][id$="1_"] {
    animation-delay: 0.1s;
}

.notIE [id^="goutte"][id$="2_"] {
    animation-delay: 0.2s;
}

.notIE [id^="goutte"][id$="3_"] {
    animation-delay: 0.3s;
}

.notIE [id^="goutte"][id$="4_"] {
    animation-delay: 0.4s;
}

.notIE [id^="goutte"][id$="5_"] {
    animation-delay: 0.5s;
}

.notIE [id^="goutte"][id$="6_"] {
    animation-delay: 0.6s;
}

.notIE [id^="goutte"][id$="7_"] {
    animation-delay: 0.7s;
}

.notIE [id^="goutte"][id$="8_"] {
    animation-delay: 0.7s;
}

.notIE [id^="goutte"][id$="9_"] {
    animation-delay: 0.9s;
}

@keyframes goutte {
    0% {
        transform: rotate(0turn) translateY(0px);
    }
    100% {
        transform: rotate(0.4turn) translateY(-10px);
    }
}

@-webkit-keyframes goutte {
    0% {
        transform: rotate(0turn) translateY(0px);
    }
    100% {
        transform: rotate(0.4turn) translateY(-10px);
    }
}
