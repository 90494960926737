body,
html {
    margin: 0;
    font-family: laca, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    width: 100vw;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.warn {
    color: #fa3232;
}

h3 {
    font-family: Recoleta;
    font-weight: 300;
    color: #3e454c;
}

p {
    font-weight: 200;
    color: #3e454c;
}

/* Header par défaut (changé dans le style de Accueil) */

header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: relative;
    padding: 50px 0;

    overflow: hidden;
    z-index: 1;
}

/* MEDIA QUERIES FOR RESPONSIVE DESIGN ********************************************/

@media screen and (max-width: 1099px) {
    html {
        font-size: 20px;
    }

    .sousTitre {
        font-size: 1.9rem;
        text-align: left;
    }

    .infos {
        margin-top: 50px;
        text-align: left;
    }

    h3 {
        font-size: 1.9rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1920px) {
    html {
        font-size: 20px;
    }

    .sousTitre {
        font-size: 1.9rem;
        width: 14em;
    }

    .infos {
        width: 16em;
    }

    h3 {
        font-size: 1.9rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1921px) {
    html {
        font-size: 27px;
    }

    .sousTitre {
        font-size: 1.9rem;
        width: 14em;
    }

    .infos {
        width: 16em;
    }

    h3 {
        font-size: 1.9rem;
    }

    p {
        font-size: 1rem;
    }
}
