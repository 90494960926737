header.accueil {
    z-index: 10;
    position: relative;
}

.headerWrapper {
}

.illustrationContainer {
    z-index: 1;
}

.greenBackground {
}

/*************/

p {
    z-index: 1;
}

h3 {
    z-index: 2;
}

.BubbleButton {
    z-index: 1;
}

.BubbleButton {
    z-index: 1;
}

.BubbleButton .circleContainer {
    z-index: 2;
}

.BubbleButton .bubbleButtonText {
    z-index: 3;
}

.EditableContainer textarea {
    z-index: 2;
}

.FramedImage img {
    z-index: 2;
}

.submitCancelButtons {
    z-index: 3;
}

.FramedImageButtonsContainer,
.SecondFramedImageButtonsContainer {
    z-index: 3;
}

.Frame.move {
    z-index: 8;
}

.uploaderBackground {
    z-index: 9;
}

.uploader {
    z-index: 10;
}
